.editor_life {
  text-align: center;
  max-width: 210mm; /* A4 width */
  padding: 10mm;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: visible;
}

.editor_life p,
.editor_life div,
.editor_life table,
.editor_life img {
  page-break-inside: avoid;
  margin: 0;
  max-width: 100%;
  height: auto;
}

/* Handle page breaks for large content elements */
.editor_life .page-break {
  page-break-before: always;
}

.organism_add{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}