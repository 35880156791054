body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
  }
  
  .dashboard_mmm {
    padding: 10px;
    background: linear-gradient(to bottom, #e8eeff, #fffbf1);;
  }
  
  .cards-container {
    display: flex;
    flex-direction: column;
    gap: 7px;
 width: 50%;
    padding: 10px;
    background-color: white;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); 
  }
  
  .char_Mnewx h3 i {
    margin-right: 8px;  /* Spacing between icon and text */
    font-size: 20px;    /* Adjust icon size */
  }
  
  .char_Mnewx {
    flex: 1;
    min-width: 47%;
    padding: 7px;
    border-radius: 12px;
    color: #fff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 98px;
        transition: box-shadow 0.3s ease;
    
  }
  
  .cards-container h3,.patient-details h3,.chart_MM h3{
    text-align: center;
  }
  .char_Mnewx:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .Booked-Booked4 {
    background-color: #4CAF50; /* Green for "Booked" */
  }
  
  .Completed-sales {
    background-color: #1E90FF; /* Blue for "Completed" */
  }
  
  .Canceled {
    background-color: #FF6347; /* Red/Orange for "Canceled" */
  }
  

  .charts-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    
  }
  
  .chart_MM {
    /* flex: 1; */
    min-width: 350px;
    height: 430px;
    background-color: #fff;
    padding: 7px;
    border-radius: 5px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);  }

  .unique-visitors {
    width: 60% !important;
    height: 100% !important;
    border-radius: 5px !important;

  }
  

  .chart_MM h3 {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
  }

  .patient-details{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }

  .css-ahj2mt-MuiTypography-root{
    font-size: 14px !important;
  }
  
  .chart_MM.customers_p93 h1 {
    margin: 0;
    font-size: 24px;
  }
  
  .customers_p93{
width: 38% !important;
height: 100% !important;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
  }

  .customers_p93_Doughnt{
    width: 320px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 320px !important;
  }


  .djfhueiki_ui {
    width: 290px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    background-color: white;
  }
  
  .rgrdfg_7u {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    gap: 10px;
    
  }
  
  .pie-chart {
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  

  .dsjje_p03w{
    display: flex;
    gap: 15px;
    /* flex-direction: column; */
  }

  .pie-chart-polar-olk{
    width: 320px ;
    height: 320px;
  }

  .customers_p93_Doughnt_od4{
width: 300px !important;
height: 300px !important;
  }
  
  @media (max-width: 768px) {
    .cards-container,
    .charts-container {
      flex-direction: column;
    }
  
    .char_Mnewx,
    .chart_MM {
      width: 100%;
    }
  
    .chart_MM {
      padding: 15px;
    }
  
    .chart_MM h3 {
      font-size: 16px;
    }
  
    .chart_MM.customers_p93 h1 {
      font-size: 20px;
    }

    .rgrdfg_7u{
      flex-direction: column;
      justify-content: center;
    }

    .cards-container{
      width: 100%;
    }

    .djfhueiki_ui{
      width: 100%;
    }
  }
  














  