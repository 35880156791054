/* css for billingInvoice and ImgPreview */
.billing {
    padding: 10px;
      /* margin-top: 35px; */

  }

  .img_section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 50px;
    margin-left: 10%;
    margin-top: 20px;
  }
  
 
  .img_2 {
    height: 200px;
    width: 200px;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .img_2 img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  
  .img_btn {
    margin-top: 20px;
    gap: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .img_btn button {
    width: 120px;
  
  }
 
  .inp_1 input {
  
    outline: none;
    border: none;
    background-color: transparent;
    padding: 10px 5px;
    color: var(--labelcolor);
  }
  .inp_1 label {
    color: var(--labelcolor);
    width: 120px;

  }
  .inp_1 input::placeholder {
    color: rgb(151,150,150);
    padding: 12px 5px;
  }




  .btn_1 {
    border: none;
    background-color: var(--ProjectColor);
    color: var(--labelcolor);
    cursor: pointer;
    width: 100px;
    border-radius: 25px;
    height: 35px;
  }
  .img_btn {
    margin-top: 20px;
    gap: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .img_btn button {
    width: 120px;
  
  }

  .acct_choose{
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    text-align: center;
    left: 1px !important;
    width: 30px !important;
  }


  .img_section_acctn2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;
   
  }


  .img_27 {
    width: 50%; /* Adjust the width of the container */
    height: 50%; /* Adjust the height of the container */
  
}

.img_27 img {
    width: 100%; /* Ensure the image takes up the full width of its container */
    height: 100%; /* Ensure the image takes up the full height of its container */
    object-fit: fill; /* Adjust object-fit property as needed */
 border: 1px solid var(--ProjectColor);
 border-radius: 5px;
 padding: 10px;
}

.img_17 {

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: 20px;
  width: 600px;
}




 
  @media (max-width: 768px)  {
    .img_section_acctn2 {
      display: flex;
      flex-direction: column;
      gap: 30px;
      /* overflow-x:hidden ;
      margin-left: 10px; */
    }

    .img_17 {

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      gap: 20px;
      width: 100%;
    }

  }


