.multiple-images-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
    gap: 20px;
    width: 100% !important;
    padding: 30px;
    justify-content: center;
    align-items: baseline;
    box-sizing: border-box;
    border: 1px double rgb(78, 77, 78);
}
.treatcon_image_1 img{
    width: 200px !important;
}
.treatcon_label label{
    font-size: 7px !important;
}