.MasterPurchaseMaster{
  margin-top: 53px;
  }   
  .pur_form_head{
    height: 40px;
    width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    background-color: hsl(33, 100%,50%);
  }
  .purchase-form h4 {
    padding-left: 10px;
   margin: 0px;
    color:#000;
    width: 100%;
    
  }
  .purchase-form {
      width: 100%; 
      padding: 10px;
      box-sizing: border-box;
      border: 1px solid #ccc;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      background-color: #fff;
    }
    .pur_flex{
      display: flex;
      flex-direction: row ;
      gap: 5px;
    }
    .Master_Inventory_Form
     {
      display: flex;
      flex-direction: column; 
      gap: 10px;
    }
    
.label_Purchase {
  font-weight: bold;
  font-size: 12px;
  color:  hsl(33, 100%,50%);
  font-size: 14px;
}


.Master_pruchase_btn{
  text-align: center;
  padding: 10px;
  justify-content:center;
  display: flex;
  gap: 30px;
}
.submit_master {
  padding: 8px 20px;
  border-radius: 30px;
  background-color:  hsl(33, 100%, 50%);
  border:1px solid hsl(33, 100%, 50%) ;
} 

.submit_master:hover {
  background-color: hsl(14, 100%, 58%);
  cursor: pointer;
}





.purchase_row_number{
  width: 100%;
  display: flex;
  flex-direction: column; 
  gap: 5px;
}
.row-buttons{
  display: flex;
  flex-direction: row;

 padding-left: 40%;
 height: 30px;

}



.search-icon_search{
  position: relative;
  margin-top: -30px;
  margin-left: 167px;
}



.mui-datatables-1ygcj2i-MuiTableCell-root {
  padding: 3px 15px !important; 
  background-color: var(--ProjectColor) !important;
}

.mui-datatables-1ex1afd-MuiTableCell-root{
  padding: 3px 15px !important; 
}



.NewTest_Master_grid_M_head_M{
  width: 100%;
  display: flex !important;
  justify-content: center !important;
  margin-top: 10px;
  overflow-x: auto !important;
}

.NewTest_Master_grid_M{
  width: 85% !important;

}

.dehduwhd_o8i th{
  background-color: var(--ProjectColor) !important;
  border: 1px solid rgba(224, 224, 224, 1);
  padding: 10px 15px !important;
  text-align: center;

}



/* .css-rqglhn-MuiTable-root {
width: 90% !important;
  display: flex;
justify-content: center;
} */

.dehduwhd_o8i{
width: 100% !important;
}





/* Styling for table body rows */
.dehduwhd_o8i tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.dehduwhd_o8i tbody tr:hover {
  background-color: #ddd;
}

/* Styling for scrollbar */
.NewTest_Master_grid_M_head_M::-webkit-scrollbar {
  width: 12px; /* Width of vertical scrollbar */
  height: 8px;
}

.NewTest_Master_grid_M_head_M::-webkit-scrollbar-track {
  background: transparent; /* Track color */
}

.NewTest_Master_grid_M_head_M::-webkit-scrollbar-thumb {
  background-color: var(--ProjectColor); /* Thumb color */
  border-radius: 6px; /* Rounded corners */
  border: 3px solid transparent; /* Border color */
}

.NewTest_Master_grid_M_head_M::-webkit-scrollbar-thumb:hover {
  background-color: var(--ProjectColorhover); 
}


.dehduwhd_o8i td{
  border-left: 1px solid rgba(224, 224, 224, 1);
  border-right:1px solid rgba(224, 224, 224, 1) ;
  padding: 0px 15px !important;
  text-align: center;
  font-family: 'Nunito';
}

.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root{
  padding: 0px !important;
  border: 1px solid #E492FF !important;
}

@media (max-width: 768px) {
  .NewTest_Master_grid_M{
    width: 100% !important;

  }
}



.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root{
  background-color: var(--selectbackgroundcolor) !important;
}

@media screen and (max-width: 768px) {

  .pur_flex {
    display: flex;
    flex-direction: column;
  }
 

}