.BodyofExpensesMaster{
  margin: 30px;
  gap:40px;
  display: flex;
  text-align: center;
}

.FirstpartOFExpensesMaster{
  width: 50%;

}

.First_Input_1{
  width:auto !important;
  display: flex;
}

.P_input_1{
  justify-content: center !important;
}

.Lable_style_1{
  color:var(--labelcolor);
  white-space: nowrap;
}
.Addnamebtn_pt1{
color:var(--labelcolor);
  height: 24px;
  width: 25px;
  border: none;
  margin-left: 10px;
  cursor: pointer;
  outline: none;
}

.Addnamebtn_pt2{
color:var(--labelcolor);
padding: 4px;
font-size: 12px;
font-weight: 700;
border: none;
cursor: pointer;
letter-spacing: 1.2px;
}

.Cash_invoice_firstpart{
padding-top: 20px;
padding-bottom: 20px;
height: auto;
/* border-bottom:1px solidvar(--ProjectColor); */

}



.Cash_QuickStock_container_form { 
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.Cash_QuickStock_container_div {
  width:20%;
  display: flex;
  align-items: center;
  gap: 10px;
 
}

.Cash_QuickStock_container_div_2{

  width:20%;
  display: flex;
  align-items: center;
  gap: 10px;

}

.Cash_containerlabel{
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 15px;
  color:var(--labelcolor);
  white-space: nowrap;
}

.cashInventory {
  width: 70%;
  padding: 0px 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  height: 28px;
  font-size: 12px;
  
}

.Cash_QuickStock_container_div_3{

  width:30%;
  display: flex;
  align-items: center;
  gap: 10px;

}

.cashInventor_2{

  width: 23%;
  padding: 0px 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  height: 28px;
  font-size: 12px;

}

.cashInventor_3{

  width: 43%;
  padding: 0px 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  height: 28px;
  font-size: 12px;

}

.Save_btn{
  background-color:var(--ProjectColor);
  color:var(--labelcolor);
  padding: 8px 15px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.cashInventory_txt{
  width: 70%;
  height: 70px;
  padding: 0px 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 12px;
  
}

.cashforwidth1{
  width: 25%;
}

.cashforwidth2{
  width: 45%;
}

.Serchbtn_cash{
  color:var(--labelcolor);
  cursor: pointer;
}

.Spanletter{
  display: flex;
  font-size: var(--fontsize);
  font-weight: bold;
  color:var(--labelcolor);
  
}
/* -------------------------------------------------------------- */
.petty_overall{
width: 95%;

}
.ShiftClosing_over{
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.ShiftClosing_Container{
  padding: 20px 0px;
  width: 98%;
  gap: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}
.ShiftClosing_header{
  width: 100%;
  height: 40px;
  background-color:var(--ProjectColor);
  color: var(--labelcolor);
  border-radius: 5px;
}
.ShiftClosing_header h3{
  margin-left: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.ShiftClosing_Invoice_container{
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.ShiftClosing_Invoice{
  width: 100%;
  height: 30px;
}
.ShiftClosing_Invoice div{
  width: 250px;
  height: 100%;
  display: flex;
  align-items: center;
}
.ShiftClosing_Invoice_span{
 padding: 0px 10px 0px 0px  ;
  width: fit-content ;
  border-bottom: 2px solid var(--ProjectColor);
}
.ShiftClosing_Invoice_span span:first-child{
  font-size: 18px;
  margin-bottom: 5px;
  color:var(--labelcolor);
}
.ShiftClosing_Invoice_span span:last-child{
  color: grey;
}
.ShiftClosing_Con{
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
}
.ShiftClosing_Con_row{
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;

}
.ShiftClosing_Con_column{
  width: 45%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.ShiftClosing_Con_column label{
  width: 150px;
  color:var(--labelcolor);
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-weight: bold;
}
.ShiftClosing_Con_column select{
  width: calc(100% - 160px);
  height: 30px;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 0px 5px;
}
.ShiftClosing_Con_column input{
  width: calc(100% - 170px);
  height: 25px;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 0px 5px;
}
.Issue_input{
  width: calc(100% - 160px);
  display: flex;
  gap: 10px;
}
.Issue_input input:first-child{
  width: 20%;
}
.Issue_input_111{
  width: 70% !important;
}
.ShiftClosing_Con_column textarea{
  width: calc(100% - 165px);
  height: 35px;
  border-radius: 5px;
  padding: 0px 5px;
}
.Shift_button{
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Shift_button button{
  width: 120px;
  height: 30px;
  border-radius: 10px;
  border: 0px;
  outline: 0px;
  padding: 0px 10px;
  background-color:var(--ProjectColor);
}

.Selected-table-container{
  width: 100%;
  overflow: auto;
  /* display: flex;
  justify-content: center; */
  
}

.saxdsed{
  width: 125px !important;
}

@media screen and (min-width:500px)and (max-width:950px) {
  .ShiftClosing_Con{
    gap: 25px;
  }
  .ShiftClosing_Con_row{
    height: auto;
    flex-direction: column;
    gap: 15px;
  }
  .ShiftClosing_Con_column{
    width: 400px;
  }
  .ShiftClosing_Con_column textarea{
    width: 230px;
  }
  .BodyofExpensesMaster{
    margin: 0px;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .FirstpartOFExpensesMaster{
    width: 100%;
  }
  .FirstpartOFExpensesMaster h2{
    font-size: 15px;
  }
  .Selected-table-container{
    width: 100%;
    min-width: 300px;
    overflow-y: scroll;
    overflow: scroll;
    
  }
  .selected-medicine-table2 {
    font-size: 14px;
  }
}
@media screen and (max-width:500px) {

  .ShiftClosing_over{
    width: 95%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .ShiftClosing_Con{
    width: 100%;
    gap: 25px;
  }
  .ShiftClosing_Con_row{
    width: 100%;
    height: auto;
    flex-direction: column;
    gap: 15px;
  }
  .ShiftClosing_Con_column{
    width: 90%;
    min-width: 300px;
  }
  .ShiftClosing_Con_column textarea{
    width: 125px;
    height: 25px;
  }
  .BodyofExpensesMaster{
    margin: 0px;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .FirstpartOFExpensesMaster{
    width: 100%;
  }
  .FirstpartOFExpensesMaster h2{
    font-size: 17px;
  }
  .Selected-table-container{
    width: 100%;
    min-width: 300px;
    
  }
  .selected-medicine-table2 {
    font-size: 14px;
  }
}



.PrintExelPdf{
  /* height: 60px; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}
.PrintExelPdf button{
  width: 120px;
  background-color:var(--ProjectColor);
  border-radius: 10px;
  border: 0px;
  outline: 0px;
  height: 30px;
  display: grid;
  place-items: center;
  color: var(--labelcolor);
}
.PrintExelPdf button:hover{
  background-color:  var(--opacityColor);
  color: white;
}

